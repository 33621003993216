export const SCHEDULED_GAME_CATEGORY = 1;

export const GAME_STATUSES = {
	NEW: 1,
	CLOSE_FOR_BETTING: 2,
	STARTED: 4,
	FINISHED: 8,
	CANCELED: 16,
	PREAMBLE_STARTED: 32
};

export const RESULTS = {
	WIN: 1,
	LOSE: 2,
	DRAW: 3
};

export const GAME_TYPE = {
	FOOTBALL_SINGLE_MATCH: 1,
	ENGLISH_LEAGUE: 2,
	HORSES_RACE: 4,
	GREYHOUNDS_RACE: 8,
	AFRICAN_CUP: 16,
	KENO: 32,
	WORLD_CUP: 64,
	EUROPEAN_CUP: 128,
	CHAMPIONS_LEAGUE: 256,
	COPA_LIBERTADORES: 512,
	HORSE_STEEPLECHASING: 1024,
	PENALTY_SHOOTOUT: 2048,
	TURKISH_LEAGUE: 4096,
	LUCKY_SIX: 8192
}

export const GAME_TYPE_MAPPER = {
	0: "virtualSports",
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: "virtualFootball",
	[GAME_TYPE.ENGLISH_LEAGUE]: "englishLeague",
	[GAME_TYPE.HORSES_RACE]: "horseRacing",
	[GAME_TYPE.GREYHOUNDS_RACE]: "greyhounds",
	[GAME_TYPE.AFRICAN_CUP]: "africanCup",
	[GAME_TYPE.WORLD_CUP]: "footballWorldCup",
	[GAME_TYPE.KENO]: "keno",
	[GAME_TYPE.EUROPEAN_CUP]: "footballEuropeanCup",
	[GAME_TYPE.CHAMPIONS_LEAGUE]: "footballChampionsLeague",
	[GAME_TYPE.COPA_LIBERTADORES]: "copaLibertadores",
	[GAME_TYPE.HORSE_STEEPLECHASING]: "steeplechase",
	[GAME_TYPE.PENALTY_SHOOTOUT]: "penaltyShootout",
	[GAME_TYPE.TURKISH_LEAGUE]: "turkishLeague",
}

export const GAME_TYPE_ICON_MAPPER = {
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: "ic_football",
	[GAME_TYPE.ENGLISH_LEAGUE]: "ic_league",
	[GAME_TYPE.HORSES_RACE]: "ic_horseRacing",
	[GAME_TYPE.GREYHOUNDS_RACE]: "ic_greyhounds",
	[GAME_TYPE.AFRICAN_CUP]: "ic_africanCup",
	[GAME_TYPE.WORLD_CUP]: "ic_worldCup",
	[GAME_TYPE.KENO]: "ic_keno",
	[GAME_TYPE.EUROPEAN_CUP]: "ic_europeanCup",
	[GAME_TYPE.CHAMPIONS_LEAGUE]: "ic_championsLeague",
	[GAME_TYPE.COPA_LIBERTADORES]: "ic_copaLibertadores",
	[GAME_TYPE.HORSE_STEEPLECHASING]: "ic_horseRaceSteeplechasing",
	[GAME_TYPE.PENALTY_SHOOTOUT]: "ic_penaltyShootout",
	[GAME_TYPE.TURKISH_LEAGUE]: "ic_turkishLeague",
}

export const GAME_EVENT_TYPE = {
	EVENT: 1,
	WEEK: 2,
	LEAGUE: 3
};

export const GAME_ACTIVITY_STATE = {
	ACTIVE: 1,
	CLOSE_FOR_BETTING: 2,
	INACTIVE: 4
};

export const GAME_LABEL_TYPE = {
	NEW: 1,
	TESTING: 2
};

export const GAME_STREAM_CONFIGURATION_TYPE = {
	WOWZA: 1,
	NANOCOSMOS: 2,
	FLUSSONIC: 4
};

export const SEASONAL_PERIODS = {
	ROUND_16: "round",
	QUARTER_FINALS: "quarterFinals",
	SEMI_FINALS: "semiFinals",
	FINAL: "final"
};

export const MAX_PENALTY_SHOOTOUT_GOAL_COUNT = new Proxy(
	{
		[GAME_TYPE.AFRICAN_CUP]: 5,
		[GAME_TYPE.WORLD_CUP]: 5,
		[GAME_TYPE.EUROPEAN_CUP]: 5,
		[GAME_TYPE.CHAMPIONS_LEAGUE]: 5,
		[GAME_TYPE.COPA_LIBERTADORES]: 5,
		DEFAULT: 0
	},
	{
		get: function (target, name) {
			return name in target ? target[name] : target.DEFAULT;
		}
	}
);

export const SCENES_LENGTH = new Proxy(
	{
		[GAME_TYPE.AFRICAN_CUP]: {
			MAIN: 6,
			OVER_TIME_GOAL_COUNT: 3,
			PENALTY_SHOOTOUT_GOAL_COUNT: 0,
			ALL: 9
		},
		[GAME_TYPE.WORLD_CUP]: {
			MAIN: 6,
			OVER_TIME_GOAL_COUNT: 3,
			PENALTY_SHOOTOUT_GOAL_COUNT: 0,
			ALL: 9
		},
		[GAME_TYPE.EUROPEAN_CUP]: {
			MAIN: 6,
			OVER_TIME_GOAL_COUNT: 3,
			PENALTY_SHOOTOUT_GOAL_COUNT: 0,
			ALL: 9
		},
		[GAME_TYPE.CHAMPIONS_LEAGUE]: {
			MAIN: 6,
			OVER_TIME_GOAL_COUNT: 3,
			PENALTY_SHOOTOUT_GOAL_COUNT: 0,
			ALL: 9
		},
		[GAME_TYPE.COPA_LIBERTADORES]: {
			MAIN: 6,
			OVER_TIME_GOAL_COUNT: 3,
			PENALTY_SHOOTOUT_GOAL_COUNT: 0,
			ALL: 9
		},
		DEFAULT: {
			MAIN: 6,
			OVER_TIME_GOAL_COUNT: 0,
			PENALTY_SHOOTOUT_GOAL_COUNT: 0,
			ALL: 6
		}
	},
	{
		get: function (target, name) {
			return name in target ? target[name] : target.DEFAULT;
		}
	}
);

export const PREDICATE_MULTIPLIER_OF_SEASON_GAMES = new Proxy(
	{
		[GAME_TYPE.ENGLISH_LEAGUE]: 38,
		[GAME_TYPE.TURKISH_LEAGUE]: 38,
		[GAME_TYPE.CHAMPIONS_LEAGUE]: 7,
		[GAME_TYPE.COPA_LIBERTADORES]: 7,
		DEFAULT: 5
	},
	{
		get: function (target, name) {
			return name in target ? target[name] : target.DEFAULT;
		}
	}
);

export const SELECTED_SEASON_GAMES = {
	NONE: 0,
	CURRENT: 1,
	NEXT: 2
};

export const GAME_EVENTS_NAVBAR_SPECIAL_KEYS = {
	NEXT_SEASON_EVENTS: "NEXT_SEASON_EVENTS",
	NEXT_SEASON_SPECIAL: "NEXT_SEASON_SPECIAL"
};

export const DEFAULT_TEAMS_FOR_STATISTICS = {
	[GAME_TYPE.AFRICAN_CUP]: {
		team1: { countryCode: "SEN", flagCode: "SEN" },
		team2: { countryCode: "ETH", flagCode: "ETH" }
	},
	[GAME_TYPE.WORLD_CUP]: {
		team1: { countryCode: "ENG", flagCode: "ENG" },
		team2: { countryCode: "BRA", flagCode: "BRA" }
	},
	[GAME_TYPE.EUROPEAN_CUP]: {
		team1: { countryCode: "SUI", flagCode: "SUI" },
		team2: { countryCode: "FRA", flagCode: "FRA" }
	},
	[GAME_TYPE.CHAMPIONS_LEAGUE]: {
		team1: { countryCode: "BAR", flagCode: "BAR" },
		team2: { countryCode: "RMA", flagCode: "RMA" }
	},
	[GAME_TYPE.COPA_LIBERTADORES]: {
		team1: { countryCode: "RIV", flagCode: "RIV" },
		team2: { countryCode: "PAL", flagCode: "PAL" }
	}
};

export const KENO_BALLS_COUNT = 80;

export const KENO_BALLS_QTY_IN_ROW = 10;

export const KENO_BALLS_QTY_IN_COLUMN = 8;

export const KENO_BALLS = Array.from({ length: KENO_BALLS_COUNT }, (_, i) => i + 1);

export const KENO_SELECTED_BALLS_MAX_COUNT = 10;

export const KENO_WINNER_BALLS_COUNT = 20;

export const KENO_BALL_SIZE = {
	NORMAL: "normal",
	SMALL: "small"
};

export const KENO_FORMAT = {
	KENO_6: 1,
	KENO_8: 2,
	KENO_10: 4,
	KENO_12: 8
};

export const KENO_FORMAT_BALLS_QTY = new Proxy(
	{
		[KENO_FORMAT.KENO_6]: 6,
		[KENO_FORMAT.KENO_8]: 8,
		[KENO_FORMAT.KENO_10]: 10,
		[KENO_FORMAT.KENO_12]: 12,
		NONE: 0
	},
	{
		get: function (target, name) {
			return name in target ? target[name] : target.NONE;
		}
	}
);

export const KENO_TABS = {
	HOT: 0,
	COLD: 1
};

export const GAME_NAVIGATION_BY_SCROLL_CLASSES = {
	VS_WRAPPER_SECTION: "vs--wrapper-section",
	VS_WRAPPER_SECTION_INNER: "vs--wrapper-section-inner",
	VS_GAME: "vs--game",
	VS_MARKETS_HOME: "vs--markets-home",
	VS_EVENTS: "vs--events",
	VS_SEASON_MARKETS_WRAPPER: "vs--season-markets-wrapper",
	VS_MARKETS_HEADER: "vs--markets-header",
	VS_MARKETS_LIST: "vs--markets-list"
};

export const STATIC_HEIGHTS_OF_ELEMENTS = {
	RACING_PLACE_MARKETS_VERTICAL_BORDER_SPACING: 2,
	RACING_PLACE_MARKETS_THEAD: 44,
	RACING_PLACE_MARKETS_TBODY_ROW: 50
};

export const PENALTY_SCENE_INFO = {
	GOAL_TOP_LEFT_SPECTER: 1 << 0 /* 1 */,
	GOAL_TOP_CENTRE_SPECTER: 1 << 1 /* 2 */,
	GOAL_TOP_RIGHT_SPECTER: 1 << 2 /* 4 */,
	GOAL_CENTRE_LEFT_SPECTER: 1 << 3 /* 8 */,
	GOAL_CENTRE_CENTRE_SPECTER: 1 << 4 /* 16 */,
	GOAL_CENTRE_RIGHT_SPECTER: 1 << 5 /* 32 */,
	GOAL_BOTTOM_LEFT_SPECTER: 1 << 6 /* 64 */,
	GOAL_BOTTOM_CENTRE_SPECTER: 1 << 7 /* 128 */,
	GOAL_BOTTOM_RIGHT_SPECTER: 1 << 8 /* 256 */,
	NOGOAL_MISS_SHOOT: 1 << 9 /* 512 */,
	NOGOAL_SAVE: 1 << 10 /* 1024 */,
	NOGOAL_WOODWORK: 1 << 11 /* 2048 */
};

export const PENALTY_SCENE_INFO_NAMES = {
	[PENALTY_SCENE_INFO.GOAL_TOP_LEFT_SPECTER]: "TopLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_TOP_CENTRE_SPECTER]: "TopCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_TOP_RIGHT_SPECTER]: "TopRightSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_LEFT_SPECTER]: "CentreLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_CENTRE_SPECTER]: "CentreCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_RIGHT_SPECTER]: "CentreRightSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_LEFT_SPECTER]: "BottomLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_CENTRE_SPECTER]: "BottomCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_RIGHT_SPECTER]: "BottomRightSpecter",
	[PENALTY_SCENE_INFO.NOGOAL_MISS_SHOOT]: "MissShoot",
	[PENALTY_SCENE_INFO.NOGOAL_SAVE]: "Save",
	[PENALTY_SCENE_INFO.NOGOAL_WOODWORK]: "Woodwork"
};

export const PENALTY_SCENE_GOAL_INFO = Object.fromEntries(Object.entries(PENALTY_SCENE_INFO).filter(([key]) => !key.includes("NOGOAL")));

export const PENALTY_SCENE_NOGOAL_INFO = Object.fromEntries(Object.entries(PENALTY_SCENE_INFO).filter(([key]) => key.includes("NOGOAL")));

export const TEAM_FORMAT = {
	COUNTRY: 1,
	CLUB: 2
};

export const GAME_CYCLE_MINUTES = {
	CYCLE1MIN: 2,
	CYCLE2MIN: 4,
	CYCLE3MIN: 8,
	CYCLE4MIN: 16,
	CYCLE5MIN: 32,
	CYCLE6MIN: 64,
};

export const STREAM_RESULT_TIMEOUT = new Proxy(
	{
		/* 10 second for last scene and for Stream Video Result */
		[GAME_TYPE.PENALTY_SHOOTOUT]: 10000,
		DEFAULT: 0
	},
	{
		get: function (target, name) {
			return name in target ? target[name] : target.DEFAULT;
		}
	}
)
