/*
	Extenssion for console functionality
	For each ENV except Production
*/

let vsLog; /* custom log method for development */
let vsLogger; /* custom log method with displaying title */
let vsLoggerShow; /* On/Off switcher for vsLogger */

if (import.meta.env.SYSTEM_APP_MODE !== "production") {
	const prefix = (arg = " ") => `%c[VS]${arg}`;
	const styles = ["color: lightblue", "font-size: 18px", "text-shadow: -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000;"].join(";");
	vsLoggerShow = import.meta.env.SYSTEM_ENV === "TST";
	vsLog = function (...data) {
		console.log(prefix(), styles, ...data);
	};
	vsLogger = function (groupName = "", ...data) {
		if (console.vsLoggerShow) {
			console.log(prefix(" " + groupName + " "), styles, ...data);
		}
	};
	vsLog = vsLog.bind(console);
	vsLogger = vsLogger.bind(console);
} else {
	const fn = function () {};
	vsLog = fn;
	vsLogger = fn;
	vsLoggerShow = false;
}

Object.defineProperty(console, "vsLog", {
	value: vsLog.bind(console),
	writable: false,
	configurable: true,
	enumerable: false
});

Object.defineProperty(console, "vsLogger", {
	value: vsLogger.bind(console),
	writable: false,
	configurable: true,
	enumerable: false
});

Object.defineProperty(console, "vsLoggerShow", {
	value: vsLoggerShow,
	writable: true,
	configurable: true,
	enumerable: false
});
