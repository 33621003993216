import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const setKenoStatisticsAction = createAction(`${sliceName}/setKenoStatistics`, (data) => {
	return {
		payload: { data }
	};
});

export const setQuickStatisticsStateAction = createAction(`${sliceName}/setQuickStatisticsState`, (state) => {
	return {
		payload: { state }
	};
});

export const setSeasonStatisticsIdAction = createAction(`${sliceName}/setSeasonStatisticsId`, (id) => {
	return {
		payload: { id }
	};
});

export const setCurrentParticipantAction = createAction(`${sliceName}/setCurrentParticipant`, (participant) => {
	return {
		payload: { participant }
	};
});

export const setRacingCurrentEventAction = createAction(`${sliceName}/setRacingCurrentEvent`, (eventId) => {
	return {
		payload: { eventId }
	};
});

export const setSeasonLiveModalMobileAction = createAction(`${sliceName}/setSeasonLiveModalMobile`, (show) => {
	return {
		payload: { show }
	};
});

export const updateSeasonStructureAction = createAction(`${sliceName}/updateSeasonStructure`, (data) => {
	return {
		payload: { data }
	};
});

export const updateSeasonStatisticsAction = createAction(`${sliceName}/updateSeasonStatistics`, (data) => {
	return {
		payload: { data }
	};
});

export const resetStatisticsStateAction = createAction(`${sliceName}/resetStatisticsState`);

export const setStatisticsTeamAction = createAction(`${sliceName}/setStatisticsTeam`, ({ index, team, gameType }) => {
	return {
		payload: { index, team, gameType }
	};
});