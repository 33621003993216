import ApiUrls from "constants/api.constants";

import httpService from "../http";

const statisticsService = {
	getCountries: (gameType, signal) => {
		return httpService.get({
			path: ApiUrls.COUNTRY_LIST,
			queryData: { gameType },
			signal
		});
	},

	getFootballTeamStatistics: ({ countryCode, teamIndex, gameType, skip, take }, signal) => {
		const params = { countryCode, gameType };
		if (skip) params.skip = skip;
		if (take) params.take = take;

		return httpService.get({
			path: ApiUrls.FOOTBALL_TEAM_STATISTICS,
			queryData: params,
			signal
		});
	},

	getPenaltyTeamStatistics: ({ countryCode, teamIndex, gameType, skip, take }, signal) => {
		const params = { countryCode, gameType };
		if (skip) params.skip = skip;
		if (take) params.take = take;

		return httpService.get({
			path: ApiUrls.PENALTY_TEAM_STATISTICS,
			queryData: params,
			signal
		});
	},

	getFootballTeamToTeamStatistics: ({ countryCode1, countryCode2, gameType }, signal) => {   // getFootballQuickStatistics
		return httpService.get({
			path: ApiUrls.FOOTBALL_TEAM_TO_TEAM_STATISTICS,
			queryData: { countryCode1, countryCode2, gameType },
			signal
		});
	},

	getPenaltyTeamToTeamStatistics: ({ countryCode1, countryCode2, gameType }, signal) => {   // getPenaltyQuickStatistics
		return httpService.get({
			path: ApiUrls.PENALTY_TEAM_TO_TEAM_STATISTICS,
			queryData: { countryCode1, countryCode2, gameType },
			signal
		});
	},

	getHeadToHeadAllTimeStatistics: ({ countryCode1, countryCode2, gameType, leagueId }, signal) => {
		return httpService.get({
			path: ApiUrls.FOOTBALL_HEAD_TO_HEAD_STATISTICS,
			queryData: { countryCode1, countryCode2, gameType, leagueId },
			signal
		});
	},

	getRaceParticipants: (gameType, signal) => {
		return httpService.get({
			path: ApiUrls.RACE_PARTICIPANTS,
			queryData: { gameType },
			signal
		});
	},

	getRaceParticipantStatistics: (id, gameType, signal) => {
		return httpService.get({
			path: ApiUrls.RACE_PARTICIPANT_STATISTICS,
			queryData: { id, gameType },
			signal
		});
	},

	getSeasonStandings: (id, signal) => {
		return httpService.get({
			path: ApiUrls.FOOTBALL_LEAGUE_STANDINGS,
			queryData: { id },
			signal
		});
	},

	getSeasonStatistics: (gameType, signal) => {
		return httpService.get({
			path: ApiUrls.FOOTBALL_LEAGUE_STATISTICS,
			queryData: { gameType },
			signal
		});
	},

	getSeasonStructure: (gameId, signal) => {
		return httpService.get({
			path: ApiUrls.GET_SEASON,
			queryData: { gameId },
			signal
		});
	},

	getLeagueQuickStatistics: ({ countryCode1, countryCode2, gameType, id }, signal) => {
		return httpService.get({
			path: ApiUrls.FOOTBALL_LEAGUE_QUICK_STATISTICS,
			queryData: { countryCode1, countryCode2, gameType, id },
			signal
		});
	},

	getKenoStatistics: (signal) => {
		return httpService.get({
			path: ApiUrls.KENO_STATISTICS,
			signal
		});
	}
};

export default statisticsService;
