import { GAME_TYPE } from "constants/game.constants"

export const GAMES_REDUCER_KEYS = new Proxy(
	{
		[GAME_TYPE.PENALTY_SHOOTOUT]: "penalty",
		DEFAULT: "football"
	},
	{
		get: function (target, name) {
			return name in target ? target[name] : target.DEFAULT;
		}
	}
);