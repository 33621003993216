import { BETSLIP_MODES, BETSLIP_STAKE_MODES } from "constants/betslip.constants";

const sliceName = "betslip";
const initialState = {
	rightbarTab: 1,
	mode: BETSLIP_MODES.SINGLE,
	stake: "",
	bets: [],
	stakeMode: BETSLIP_STAKE_MODES.PER_BET,
	showKeyboard: false,
	success: false,
	loading: false,
	isCanceling: false,
	pendings: [],
	pendingLoading: false,
	betslipLastResults: [],
	showMobileBetslipsModal: false
}

export { sliceName, initialState };