import { Fragment, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import Loader from "components/ui/loader";
import ErrorMessage from "components/ui/errorMessage";
import BlockedMessage from "components/ui/blockedMessage";
import Maintenance from "components/ui/maintenance";
import MobileHeader from "components/mobileHeader";
import ConditionalStyles from "components/ui/conditionalStyles";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";
import useGlobalVariables from "hooks/useGlobalVariables";
import useAsyncEffect from "hooks/useAsyncEffect";

import { authenticateThunk, createSessionThunk, createStreamAccessThunk, sessionDetailsThunk } from "store/slices/auth/thunks";
import { setPlayerAction, setSessionFailedAction } from "store/slices/auth/actions";
import { 
	selectSessionLoaded, 
	selectSessionFailed, 
	selectSessionMaintenanceMode,
	selectTranslationsLoaded
} from "store/slices/auth/selectors";
import { selectBlockedMessage, selectErrorMessage } from "store/slices/common/selectors";

import { initTranslations } from "translations/config";

import { showError } from "utils/message";
import { getUrlVars } from "utils/common";
import { appendCss, buildCssUrl } from "utils/css";
import Paths from "constants/path.constants";
import { GAME_ACTIVITY_STATE } from "constants/game.constants";
import { ERROR_MESSAGE, ERROR_RESOURCE, ERROR_STATUSES } from "constants/message.constants";

const RootLayout = () => {
	const sessionLoaded = useAppSelector(selectSessionLoaded);
	const sessionFailed = useAppSelector(selectSessionFailed);
	const maintenanceMode = useAppSelector(selectSessionMaintenanceMode);
	const translationsLoaded = useAppSelector(selectTranslationsLoaded);
	const blockedMessage = useAppSelector(selectBlockedMessage);
	const errorMessage = useAppSelector(selectErrorMessage);
	const dispatch = useAppDispatch();
	const globalVariables = useGlobalVariables();

	const variableRef = useRef();
	variableRef.current = globalVariables;
	const { isMobile, isBuilderIntegration } = globalVariables;

	useLayoutEffect(() => {
		showError.dispatch = dispatch;
	}, []);

	useAsyncEffect(async () => {
		try {
			const isStream = window.location.pathname === Paths.STREAM;
			const urlVars = getUrlVars();

			if (isStream) {
				const guid = urlVars["guid"];
				const gameId = urlVars["gameId"];

				if (!guid || !gameId) {
					initTranslations(null, "en", () => {
						dispatch(setSessionFailedAction(true));
						showError({ 
							message: !guid ? ERROR_MESSAGE.GUID_NOT_FOUND : ERROR_MESSAGE.GAME_NOT_FOUND
						});
					});
				} else {
					await dispatch(createStreamAccessThunk()).unwrap();
				}
			}

			let sessionId = urlVars.sessionId;

			if (!sessionId) {
				const data = await dispatch(createSessionThunk()).unwrap();
				sessionId = data?.session.sessionId;
			} else {
				const cssFilePath = urlVars["cssFilePath"];
				if (cssFilePath) {
					let url = decodeURIComponent(cssFilePath);
					appendCss(url);
				}
			}

			await dispatch(authenticateThunk(sessionId)).unwrap();
			const { data: session } = await dispatch(sessionDetailsThunk(globalVariables)).unwrap();

			/**Append css */
			const cssId = session?.cssId;
			if (!urlVars["cssFilePath"] && cssId && session.projectId && session.partnerId) {
				appendCss(buildCssUrl(cssId, session.partnerId, session.projectId));
			}

			const onTranslationSuccess = () => {
				const desktopEnabled = session?.securitySettings?.desktop;
				const iosEnabled = session?.securitySettings?.ios;
				const androidEnabled = session?.securitySettings?.android;
				const activeGamesCount = (session?.games ?? []).filter((g) => g.state !== GAME_ACTIVITY_STATE.INACTIVE).length;

				if ((!variableRef.current.isMobile && !desktopEnabled) || (variableRef.current.isIOS && !iosEnabled) || (variableRef.current.isAndroid && !androidEnabled)) {
					dispatch(setSessionFailedAction(true));
					showError({
						message: ERROR_MESSAGE.BLOCKED,
						status: ERROR_STATUSES.NOT_ALLOWED,
						resource: ERROR_RESOURCE.PLAYER_DEVICE
					});
				} else if (activeGamesCount === 0) {
					dispatch(setSessionFailedAction(true));
					showError({ 
						message: ERROR_MESSAGE.INVALID_PLAYED_DATA
					});
				}
			};

			initTranslations(session.languageFilePath, session.languageId, onTranslationSuccess);
		} catch (error) {
			initTranslations(null, "en", () => {
				dispatch(setSessionFailedAction(true));
			});
			console.log(error);
		}
	}, []);

	switch (true) {
		case sessionFailed && translationsLoaded:
			return (
				<Fragment>
					<ConditionalStyles />
					{blockedMessage ? <BlockedMessage title="common.gamesAreBlocked" message={blockedMessage} /> : null}
					{errorMessage ? <ErrorMessage /> : null}
					{maintenanceMode ? (
						<Fragment>
							{isMobile && !isBuilderIntegration ? <MobileHeader /> : null}
							<Maintenance />
						</Fragment>
					) : null}
				</Fragment>
			);
		case !sessionLoaded:
			return (
				<Fragment>
					{errorMessage ? <ErrorMessage /> : null}
					<Loader full={true} />
				</Fragment>
			);
		case sessionLoaded && !sessionFailed && translationsLoaded:
			return (
				<Fragment>
					<ConditionalStyles />
					{errorMessage ? <ErrorMessage /> : null}
					<Outlet />
				</Fragment>
			);
		default:
			return <Fragment />;
	}
};

export default RootLayout;
