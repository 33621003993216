import { useTranslation } from 'react-i18next';
import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common";
import useGlobalVariables from "hooks/useGlobalVariables";

/** Maintenance Component */
const Maintenance = () => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();

	return (
		<div className="vs--maintenance vs--flex vs--flex-col vs--align-center vs--justify-center vs--pl-8 vs--pr-8">
			<img
				src={buildPathToStaticFolderOfCDN("images/misc/maintenance.svg")}
				alt="maintenance"
			/>
			<b className="vs--maintenance-text vs--title vs--mt-24 vs--font-medium">{t('common.maintenanceModeText')}</b>
			<span
				className={
					mergeClassNames(
						'vs--title vs--mt-8 vs--font-regular vs--font-big',
						isMobile && 'vs--maintenance-sub-text'
					)
				}
			>
				{t('common.maintenanceModeSubText')}
			</span>
		</div>
	);
};

export default Maintenance;
