import { sliceName } from "./initialState";

const emptyObj = {};
const emptyArray = [];

export const selectKenoStatistics = (state) => state[sliceName].kenoStatistics;
export const selectIsLoading = (state) => state[sliceName].laoding;

export const selectQuickStatistics = (state) => state[sliceName].quickStatistics;
export const selectFootballStatistics = (state) => state[sliceName].football;
export const selectPenaltyStatistics = (state) => state[sliceName].penalty;
export const selectRacingStatistics = (state) => state[sliceName].racing;
export const selectSeasonStatistics = (state) => state[sliceName].season;

export const selectQuickStatisticsState = (state) => selectQuickStatistics(state).state;
export const selectIsQuickStatisticsLoading = (state) => selectQuickStatistics(state).loading;
export const selectQuickStatisticsData = (state) => selectQuickStatistics(state).statistics;
export const selectQuickStatisticsTeamsData = (state) => selectQuickStatistics(state).teamsData;

export const selectFootballStatisticsCountries = (state) => selectFootballStatistics(state).countries;
export const selectFootballStatisticsTeam1 = (state) => selectFootballStatistics(state).team1;
export const selectFootballStatisticsTeam2 = (state) => selectFootballStatistics(state).team2;
export const selectFootballTeam1Statistics = (state) => selectFootballStatistics(state).team1Statistics;
export const selectFootballTeam2Statistics = (state) => selectFootballStatistics(state).team2Statistics;
export const selectFootballStatisticsLeaguePositions = (state) => selectFootballStatistics(state).leaguePositions;
export const selectFootballStatisticsH2h = (state) => selectFootballStatistics(state).h2h;

export const selectPenaltyStatisticsCountries = (state) => selectPenaltyStatistics(state).countries;
export const selectPenaltyStatisticsTeam1 = (state) => selectPenaltyStatistics(state).team1;
export const selectPenaltyStatisticsTeam2 = (state) => selectPenaltyStatistics(state).team2;
export const selectPenaltyTeam1Statistics = (state) => selectPenaltyStatistics(state).team1Statistics;
export const selectPenaltyTeam2Statistics = (state) => selectPenaltyStatistics(state).team2Statistics;
export const selectPenaltyStatisticsH2h = (state) => selectPenaltyStatistics(state).h2h;

export const selectRacingStatisticsCurrParticipant = (state) => selectRacingStatistics(state).currentParticipant;
export const selectRacingStatisticsCurrEvent = (state) => selectRacingStatistics(state).currentEvent;
export const selectRacingStatisticsData = (state) => selectRacingStatistics(state).statistics;

export const selectSeasonStructure = (state) => selectSeasonStatistics(state).structure || emptyObj;
export const selectSeasonStandings = (state) => selectSeasonStatistics(state).standings || emptyArray;
export const selectSeasonShowLiveModal = (state) => selectSeasonStatistics(state).showSeasonLiveModal;
export const selectIsSeasonLoading = (state) => selectSeasonStatistics(state).loading ?? false;
export const selectIsSeasonUpdateRequestSended = (state) => selectSeasonStatistics(state).isUpdateRequestSended ?? false;