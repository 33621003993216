import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import Navbar from "components/ui/navbar";

import useAppSelector from "hooks/store/useAppSelector";

import { selectPendingBets } from "store/slices/betslip/selectors";

import { navigateToPage } from "utils/navigate";
import { MENU_ITEMS } from "constants/menu.contants";
import Paths from "constants/path.constants";

/* Menu Component in Header */
const Menu = ({ dir }) => {
	return (
		<div className="vs--flex vs--justify-between vs-align-center vs--flex-equal">
			<Navbar dir={dir} menuButton={false} elementId="id" className="vs--header-menu" dataList={MENU_ITEMS} elementInnerComponent={Menu.elementInnerComponent} />
		</div>
	);
};

Menu.elementInnerComponent = ({ data: item }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const pendings = useAppSelector(selectPendingBets);

	/** Function to check if the menu item is active
	 * @function
	 * @param {object} item - menu item to check
	 * @returns {boolean}
	 * @memberOf Menu
	 */
	const isActiveLink = (item) => (item.path !== "/" ? location.pathname.includes(item.path) || (item.pathsToSelect && item.pathsToSelect.some((p) => location.pathname.includes(p.replace(":id", "")))) : location.pathname === item.path);
	return (
		<div
			key={item.id}
			className={"vs--header-menu-item vs--flex vs--align-center vs--pl-24 vs--pr-24" + (isActiveLink(item) ? " vs--header-menu-item-active" : "")}
			onClick={() => (item.onClick ? item.onClick() : !isActiveLink(item) ? navigateToPage(navigate, item.path) : false)}
			data-unique={item.id}
		>
			<span className="vs--header-menu-item-title vs--title-gray-50 vs--font-regular vs--font-normal">{t(item.title)}</span>
			{item.path === Paths.HISTORY && pendings.length > 0 && <b data-element-id="badge" className="vs--badge vs--title-black vs--font-smallest vs--font-bold vs--ml-6">{pendings.length}</b>}
		</div>
	);
};

Menu.elementInnerComponent.displayName = "MenuElementInnerComponent";
Menu.elementInnerComponent.propTypes = {
	data: PropTypes.any
};

export default Menu;

Menu.propTypes = {
	dir: PropTypes.string
};
