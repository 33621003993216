import { createAsyncThunk } from "@reduxjs/toolkit";

import statisticsService from "services/api/statistics";

import { sliceName } from "./initialState";

export const getCountriesThunk = createAsyncThunk(`${sliceName}/getCountries`, async (gameType, { signal }) => {
	const response = await statisticsService.getCountries(gameType, signal);

	return response.data.value;
});

export const getFootballTeamStatisticsThunk = createAsyncThunk(`${sliceName}/getFootballTeamStatistics`, async ({
	countryCode, teamIndex, gameType
}, { signal }) => {
	const response = await statisticsService.getFootballTeamStatistics({ countryCode, gameType }, signal);
	const teamStatistics = teamIndex === 1 ? "team1Statistics" : "team2Statistics";

	return { data: response.data.value, teamStatistics };
});

export const getPenaltyTeamStatisticsThunk = createAsyncThunk(`${sliceName}/getPenaltyTeamStatistics`, async ({
	countryCode, teamIndex, gameType
}, { signal }) => {
	const response = await statisticsService.getPenaltyTeamStatistics({ countryCode, gameType }, signal);
	const teamStatistics = teamIndex === 1 ? "team1Statistics" : "team2Statistics";

	return { data: response.data.value, teamStatistics };
});

export const getFootballTeamToTeamStatisticsThunk = createAsyncThunk(`${sliceName}/getFootballTeamToTeamStatistics`, async ({
	countryCode1, countryCode2, gameType
}, { signal }) => {
	const response = await statisticsService.getFootballTeamToTeamStatistics({ countryCode1, countryCode2, gameType }, signal);

	return response.data.value;
});

export const getPenaltyTeamToTeamStatisticsThunk = createAsyncThunk(`${sliceName}/getPenaltyTeamToTeamStatistics`, async ({
	countryCode1, countryCode2, gameType
}, { signal }) => {
	const response = await statisticsService.getPenaltyTeamToTeamStatistics({ countryCode1, countryCode2, gameType }, signal);

	return response.data.value;
});

export const getHeadToHeadAllTimeStatisticsThunk = createAsyncThunk(`${sliceName}/getHeadToHeadAllTimeStatistics`, async ({
	countryCode1, countryCode2, gameType, leagueId
}, { signal }) => {
	const response = await statisticsService.getHeadToHeadAllTimeStatistics({ countryCode1, countryCode2, gameType, leagueId }, signal);

	return response.data.value;
});

export const getRaceParticipantsThunk = createAsyncThunk(`${sliceName}/getRaceParticipants`, async (gameType, { signal }) => {
	const response = await statisticsService.getRaceParticipants(gameType, signal);

	return response.data.value;
});

export const getRaceParticipantStatisticsThunk = createAsyncThunk(`${sliceName}/getRaceParticipantStatistics`, async ({ id, gameType }, { signal }) => {
	const response = await statisticsService.getRaceParticipantStatistics(id, gameType, signal);

	return response.data.value;
});

export const getSeasonStatisticsThunk = createAsyncThunk(`${sliceName}/getSeasonStatistics`, async (gameType, { signal }) => {
	const response = await statisticsService.getSeasonStatistics(gameType, signal);

	return response.data.value;
});

export const getSeasonStructureThunk = createAsyncThunk(`${sliceName}/getSeasonStructure`, async (gameId, { signal }) => {
	const response = await statisticsService.getSeasonStructure(gameId, signal);

	return response.data.value;
});

export const getFootballQuickStatisticsThunk = createAsyncThunk(`${sliceName}/getFootballQuickStatistics`, async ({
	countryCode1, countryCode2, gameType
}, { signal }) => {
	const response = await statisticsService.getFootballTeamToTeamStatistics({ countryCode1, countryCode2, gameType }, signal);

	return response.data.value;
});

export const getPenaltyQuickStatisticsThunk = createAsyncThunk(`${sliceName}/getPenaltyQuickStatistics`, async ({
	countryCode1, countryCode2, gameType
}, { signal }) => {
	const response = await statisticsService.getPenaltyTeamToTeamStatistics({ countryCode1, countryCode2, gameType }, signal);

	return response.data.value;
});

export const getLeagueQuickStatisticsThunk = createAsyncThunk(`${sliceName}/getLeagueQuickStatistics`, async ({
	id, countryCode1, countryCode2, gameType
}, { signal }) => {
	const response = await statisticsService.getLeagueQuickStatistics({ id, countryCode1, countryCode2, gameType }, signal);

	return response.data.value;
});

export const getKenoStatisticsThunk = createAsyncThunk(`${sliceName}/getKenoStatistics`, async (_, { signal }) => {
	const response = await statisticsService.getKenoStatistics(signal);

	return response.data.value;
});