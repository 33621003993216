import { createSlice } from "@reduxjs/toolkit";

import {
	addCommonCases,
	addCountriesCases,
	addTeamStatisticsCases,
	addTeamToTeamStatisticsCases,
	addRacingStatisticsCases,
	addKenoStatisticsCases,
	addSeasonStatisticsCases,
	addQuickStatisticsCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const statisticsSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addCommonCases(builder);
		addCountriesCases(builder);
		addTeamStatisticsCases(builder);
		addTeamToTeamStatisticsCases(builder);
		addRacingStatisticsCases(builder);
		addKenoStatisticsCases(builder);
		addSeasonStatisticsCases(builder);
		addQuickStatisticsCases(builder);
	}
});

export const statisticsSliceName = sliceName;
export const statisticsSliceReducer = statisticsSlice.reducer;