import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setShowMobileBetSlipsModalAction, placeBetSuccessAction } from "store/slices/betslip/actions";
import { selectIsQuickBetEnabled } from "store/slices/auth/selectors";
import { selectPendingBets, selectBets, selectShowMobileBetslipsModal } from "store/slices/betslip/selectors";

import Paths from "constants/path.constants";

import MobileBetslip from "components/ui/mobileBetslip";

import { mergeClassNames } from "utils/common";
import { navigateToPage, redirectToPage } from "utils/navigate";

import { QUICK_BET_ANIMATABLE_EL_DATA_ATTRS } from "constants/betslip.constants";

import OutsideAlerter from "components/ui/outsideAlerter";

import MenuComboBoost from "components/comboBoost/menuComboBoost";

/* Mobile Menu Component */
const MobileMenu = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const dispatch = useAppDispatch();

	const [subOpened, setSubOpened] = useState(false);

	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const pendings = useAppSelector(selectPendingBets);
	const bets = useAppSelector(selectBets);
	const showMobileBetslipsModal = useAppSelector(selectShowMobileBetslipsModal);

	const betsCount = bets.filter((bet) => !bet.isExpired && !bet.hidden).length;

	const hideSuccessMessage = () => dispatch(placeBetSuccessAction(false));

	/** Function to check if the menu item is active
	 * @function
	 * @param {string} path - path to check
	 * @returns {boolean}
	 * @memberOf MobileMenu
	 */
	const isActiveLink = (path) => (path !== "/" ? location.pathname.includes(path) : location.pathname === path) && !showMobileBetslipsModal;

	/** Function to navigate to another page
	 * @function
	 * @param {string} path - path to navigate
	 * @memberOf MobileMenu
	 */
	const handleNavigate = (path) => {
		dispatch(setShowMobileBetSlipsModalAction(false));
		setSubOpened(false);

		if (path === Paths.SETTINGS && showMobileBetslipsModal) {
			redirectToPage(navigate, path);
		} else {
			navigateToPage(navigate, path);
		}
	};

	useEffect(() => {
		if (showMobileBetslipsModal) {
			document.body.classList.add("vs--overflow-hidden");
		} else {
			document.body.classList.remove("vs--overflow-hidden");
		}
	}, [showMobileBetslipsModal]);

	return (
		<div className="vs--mobile-menu">
			<MenuComboBoost />
			<div className="vs--flex">
				<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (isActiveLink(Paths.HOME) ? " vs--mobile-menu-item-active" : "")} onClick={() => handleNavigate(Paths.HOME)}>
					<i className="ic_home vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.home")}</span>
				</div>
				<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (isActiveLink(Paths.STATISTICS) ? " vs--mobile-menu-item-active" : "")} onClick={() => handleNavigate(Paths.STATISTICS)}>
					<i className="ic_statistics vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.statistics")}</span>
				</div>

				<div
					className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (showMobileBetslipsModal ? " vs--mobile-menu-item-active" : "")}
					data-element-id={QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BETSLIP}
					onClick={() => {
						hideSuccessMessage();
						dispatch(setShowMobileBetSlipsModalAction(true));
					}}
				>
					<i className={mergeClassNames("vs--font-bigest", isQuickBetEnabled ? "ic_thunder" : "ic_betslip")} />
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.betslip")}</span>
					{betsCount > 0 && !isQuickBetEnabled ? <b className="vs--mobile-menu-item-count vs--title-black vs--font-smallest vs--font-bold">{betsCount}</b> : null}
				</div>
				<div 
					className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (isActiveLink(Paths.HISTORY) ? " vs--mobile-menu-item-active" : "")} 
					data-element-id={QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BET_HISTORY}
					onClick={() => handleNavigate(Paths.HISTORY)}
				>
					<i className="ic_history vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.betsHistory")}</span>
					{pendings.length > 0 ? <b className="vs--mobile-menu-item-count vs--title-black vs--font-smallest vs--font-bold">{pendings.length}</b> : null}
				</div>
				<div
					className={
						"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item vs--mobile-menu-item-preventClass" +
						(isActiveLink(Paths.RESULTS) || isActiveLink(Paths.SETTINGS) || isActiveLink(Paths.RULES) || isActiveLink(Paths.BONUS) || isActiveLink(Paths.EVENT.replace("/:id", "")) || subOpened ? " vs--mobile-menu-item-active" : "")
					}
					onClick={() => setSubOpened(!subOpened)}
				>
					<i className="ic_more vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.more")}</span>
				</div>
			</div>
			<OutsideAlerter callback={() => setSubOpened(false)} preventClassName="vs--mobile-menu-item-preventClass">
				<div className={"vs--flex vs--flex-col vs--flex-grow vs--mobile-submenu vs-font-regular vs-font-small" + (!subOpened ? " vs--mobile-submenu-hidden" : "")}>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.BONUS) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.BONUS)}>
						<i className="ic_bonus vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("bonus.bonus")}</span>
					</div>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.RESULTS) || isActiveLink(Paths.EVENT.replace("/:id", "")) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.RESULTS)}>
						<i className="ic_results vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.results")}</span>
					</div>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.RULES) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.RULES)}>
						<i className="ic_rules vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.rules")}</span>
					</div>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.SETTINGS) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.SETTINGS)}>
						<i className="ic_settings vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.settings")}</span>
					</div>
				</div>
			</OutsideAlerter>
			{showMobileBetslipsModal && (
				<MobileBetslip />
			)}
		</div>
	);
};

export default MobileMenu;
